<div class="modal-container" *ngIf="!isLoading">
    <div class="modal-header">
        <div class="modal-title">
            <ng-container *ngIf="data">
                Modifica terminale
            </ng-container>
            <ng-container *ngIf="!data">
                Aggiungi terminale
            </ng-container>
        </div>
        <div class="modal-close">
            <x-circle-outline-icon class="clickable" (click)="closeMe()"></x-circle-outline-icon>
        </div>
    </div>
    <form class="modal-content grid grid-2" [formGroup]="form">
        <ng-container *ngIf="isInError">
            Errore nel recuperare la lista clienti.
        </ng-container>
        <ng-container *ngIf="!isInError">

            <div class="field field-grid">
                <div class="title">ID Terminale</div>
                <input type="text" formControlName="c_custom_id">
            </div>
            <div class="field field-grid">
                <div class="title">Numero seriale</div>
                <input type="text" formControlName="c_sn">
            </div>
            <div class="field field-grid">
                <div class="title">Nome terminale</div>
                <input type="text" formControlName="c_name">
            </div>
            <div class="field field-grid">
                <div class="title">Modello terminale</div>
                <mat-select [(ngModel)]="selectedModel" formControlName="c_model"
                    (selectionChange)="onCustomerSelectionChanged()">
                    <input class="filterSearch" placeholder="Cerca modello" (keyup)="onModelListFilter($event.target)">
                    <mat-option *ngFor="let item of clockModelList" [value]="item.cm_name">
                        {{item.cm_name}}
                    </mat-option>
                </mat-select>
            </div>
            <div class="field field-grid">
                <div class="title">Note</div>
                <input type="text" formControlName="c_note">
            </div>
            <div class="field field-grid">
                <div class="title">Descrizione</div>
                <input type="text" formControlName="c_desc">
            </div>
            <div class="field field-grid">
                <div class="title">Ubicazione</div>
                <input type="text" formControlName="c_location">
            </div>
            <div class="field field-grid">
                <div class="title">Cliente</div>
                <mat-select [(ngModel)]="selectedCustomer" formControlName="c_fk_cst"
                    (selectionChange)="onCustomerSelectionChanged()">
                    <input class="filterSearch" placeholder="Cerca cliente"
                        (keyup)="onCustomerListFilter($event.target)">
                    <mat-option *ngFor="let item of customerList" [value]="item.customer_name">
                        {{item.customer_name}}
                    </mat-option>
                </mat-select>
            </div>
        </ng-container>
    </form>
    <div class="modal-footer">
        <ng-container *ngIf="!isInError">
            <ng-container *ngIf="data">
                <div class="button" (click)="canSendRequest && onUpdateTerminal()"
                    [ngClass]="[canSendRequest ? 'button-primary': 'button-disabled']">Aggiorna terminale</div>
            </ng-container>
            <ng-container *ngIf="!data">
                <div class="button" (click)="canSendRequest && onCreateTerminal()"
                    [ngClass]="[canSendRequest ? 'button-primary': 'button-disabled']">Aggiungi terminale</div>
            </ng-container>

        </ng-container>
    </div>
</div>